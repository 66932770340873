import React from 'react';
import PropTypes from 'prop-types';
import styled, {createGlobalStyle} from 'styled-components';
import KoldyLogo from '../components/logo/KoldyLogo';

const MainLayout = function (props) {
	const {children} = props;

	return (
		<StyledMainLayout>
			<GlobalStyle />
			<Logo>
				<KoldyLogo size={100} />
			</Logo>
			<Heading>Koldy UI</Heading>
			<Main>{children}</Main>
		</StyledMainLayout>
	);
};

MainLayout.propTypes = {
	children: PropTypes.node,
};

const StyledMainLayout = styled.div`
	display: block;
	padding: 3rem 0;
`;

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #935d8c;
    background-image: linear-gradient(45deg, #e37682, #5f4d93);
    color: #222222;
    min-height: 100vh;
  }
`;

const Logo = styled.div`
	display: block;
	text-align: center;
	padding: 1rem;
`;

const Heading = styled.h1`
	display: block;
	text-align: center;
	padding: 0;
	margin: 0;
	font-size: 2.6rem;
	color: rgba(255, 255, 255, 0.92);
`;

const Main = styled.main`
	display: block;
	padding: 1rem;
	margin: 3rem auto;
	width: 100%;
	max-width: 900px;
`;

export default MainLayout;
