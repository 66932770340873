import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const KoldyLogo = function ({size, color = '#ffffff'}) {
	return (
		<Svg
			width={size}
			height={size}
			viewBox="197.09498596191406 324.3339538574219 454.63507080078125 414.0020446777344"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			$color={color}
		>
			<g>
				<path d="M651.726,324.334l-119.358,0c-33.727,0 -54.533,20.849 -54.533,20.849l-186.154,186.152l186.154,186.154c0,0 20.806,20.847 54.533,20.847l119.358,0l-204.55,-207.001l204.55,-207.001Zm0,0l0.004,0l-0.004,0Zm-46.905,19.594l-171.583,173.635c-7.54,7.631 -7.54,19.912 0,27.542l171.583,173.638l-72.453,0c-24.335,0 -39.718,-14.2 -40.764,-15.196l-172.214,-172.212l172.214,-172.212c1.046,-0.995 16.429,-15.195 40.764,-15.195l72.453,0" />
				<path d="M266.886,557.27l-69.791,73.301c0,0 9.051,44.965 81.76,44.965l105.708,0l-117.677,-118.266Zm0.31,28.092l70.232,70.58l-58.573,0c-23.27,0 -41.519,-5.297 -52.773,-15.32c-2.382,-2.122 -4.217,-4.243 -5.622,-6.176l46.736,-49.084" />
			</g>
		</Svg>
	);
};

KoldyLogo.propTypes = {
	size: PropTypes.number.isRequired,
	color: PropTypes.string,
};

const Svg = styled.svg`
	fill-rule: evenodd;
	clip-rule: evenodd;
	stroke-linejoin: round;
	stroke-miterlimit: 1.41421;

	> g > path {
		fill: ${({$color}) => $color};
		fill-rule: nonzero;
	}
`;

export default KoldyLogo;
